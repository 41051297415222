export default [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    customRender: (value, item, index) => {
      return index + 1
    },
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
  },
  {
    title: '产品名称',
    dataIndex: 'material_name',
  },
  {
    title: '产品编号',
    dataIndex: 'material_number',
  },
  {
    title: '仓库名称',
    dataIndex: 'warehouse_name',
  },
  {
    title: '仓库编号',
    dataIndex: 'warehouse_number',
  },
  {
    title: '流水类型',
    dataIndex: 'type_display',
  },
  {
    title: '变化之前数量',
    dataIndex: 'quantity_before',
  },
  {
    title: '变化数量',
    dataIndex: 'quantity_change',
  },
  {
    title: '变化之后数量',
    dataIndex: 'quantity_after',
  },
  {
    title: '单据编号',
    dataIndex: 'order_number',
  },
  {
    title: '创建人',
    dataIndex: 'creator_name',
  },
]